<template>
  <router-layout>
    <div class="page">
      <div class="form">
        <div class="form_t">基本信息</div>
        <div class="input">
          <van-field v-model="type" input-align="right" readonly required label="类别" />
        </div>
        <div class="input">
          <van-field
            v-model="title"
            clearable
            input-align="right"
            required
            label="标题"
            placeholder="请输标题,40字以内"
            maxlength="40"
          />
        </div>
        <div class="input">
          <van-field
            v-model="area"
            input-align="right"
            is-link
            readonly
            required
            label="区域"
            placeholder="请选择"
            @click="areaPicker"
          />
        </div>
        <div class="input noborder">
          <van-field
            v-model="time"
            input-align="right"
            is-link
            readonly
            required
            label="有效期"
            placeholder="请选择"
            @click="timePicker"
          />
        </div>
        <div class="form_t">招聘详情</div>
        <div class="input">
          <van-field
            v-model="sex"
            input-align="right"
            is-link
            readonly
            required
            label="性别"
            placeholder="请选择"
            @click="sexPicker"
          />
        </div>
        <div class="input">
          <van-field
            v-model="wage"
            input-align="right"
            required
            is-link
            readonly
            label="月薪"
            placeholder="请选择"
            @click="wagePicker"
          />
        </div>
        <div class="input">
          <van-field v-model="job" input-align="right" required label="职位" placeholder="请输入" />
        </div>
        <div class="input">
          <van-field
            v-model="school"
            input-align="right"
            is-link
            readonly
            required
            label="学历"
            placeholder="请选择"
            @click="schoolPicker"
          />
        </div>
        <div class="input noborder">
          <van-field
            v-model="year"
            input-align="right"
            is-link
            readonly
            required
            label="工作年限"
            placeholder="请选择"
            @click="yearPicker"
          />
        </div>
        <div class="line"></div>
        <div class="input noborder">
          <div class="input_t">工作需求</div>
          <van-field
            v-model="detail"
            rows="4"
            autosize
            type="textarea"
            maxlength="300"
            placeholder="可以详细工作的需求增加招聘人才的机会"
            show-word-limit
            clearable
          />
        </div>
        <div class="form_t">公司信息</div>
        <div class="input">
          <van-field v-model="company" input-align="right" required label="公司名称" placeholder="请输入" />
        </div>
        <div class="input">
          <van-field
            v-model="nature"
            input-align="right"
            is-link
            readonly
            required
            label="公司性质"
            placeholder="请选择"
            @click="naturePicker"
          />
        </div>
        <div class="input">
          <van-field v-model="name" input-align="right" required label="联系人" placeholder="请输入" />
        </div>
        <div class="input">
          <van-field
            v-model="phone"
            input-align="right"
            required
            label="手机"
            placeholder="请输入"
            type="number"
            maxlength="11"
          />
        </div>
        <div class="line"></div>
        <div class="input noborder">
          <div class="input_t">公司福利</div>
          <div class="checkBox">
            <van-checkbox-group v-model="welfareSelect" direction="horizontal">
              <div
                class="checkItem"
                :class="{active:item.checked}"
                v-for="item,index in welfare"
                :key="index"
                @click="selectWelfare(item)"
              >
                <van-checkbox :name="item.value">{{item.txt}}</van-checkbox>
              </div>
            </van-checkbox-group>
          </div>
          <!--  -->
        </div>
        <div class="line"></div>
        <div class="input noborder">
          <div class="input_t">照片</div>
          <div class="photo">
            <van-uploader
              v-model="photo"
              multiple
              :max-count="2"
              :max-size="imgMaxSize"
              @oversize="onOversize"
              @delete="deleteImg"
              :after-read="uploadReady"
            />
          </div>
        </div>
        <!--  -->
      </div>
      <div class="submit" @click="submit">确定发布</div>
      <!-- 区域弹窗 -->
      <van-popup v-model="areaPickerShow" position="bottom">
        <van-picker
          title="区域"
          show-toolbar
          :default-index="areaIndex"
          :columns="areaPick"
          @cancel="areaCancel"
          @confirm="areaConfirm"
        />
      </van-popup>
      <!-- 有效期弹窗 -->
      <van-popup v-model="timePickerShow" position="bottom">
        <van-picker
          title="有效期"
          show-toolbar
          :default-index="timeIndex"
          :columns="timePick"
          @cancel="timeCancel"
          @confirm="timeConfirm"
        />
      </van-popup>
      <!-- 月薪弹窗 -->
      <van-popup v-model="wagePickerShow" position="bottom">
        <van-picker
          title="月薪"
          show-toolbar
          :default-index="wageIndex"
          :columns="wagePick"
          @cancel="wageCancel"
          @confirm="wageConfirm"
        />
      </van-popup>
      <!-- 学历弹窗 -->
      <van-popup v-model="schoolPickerShow" position="bottom">
        <van-picker
          title="学历"
          show-toolbar
          :default-index="schoolIndex"
          :columns="schoolPick"
          @cancel="schoolCancel"
          @confirm="schoolConfirm"
        />
      </van-popup>
      <!-- 工作年限弹窗 -->
      <van-popup v-model="yearPickerShow" position="bottom">
        <van-picker
          title="工作年限"
          show-toolbar
          :default-index="yearIndex"
          :columns="yearPick"
          @cancel="yearCancel"
          @confirm="yearConfirm"
        />
      </van-popup>
      <!-- 公司性质弹窗 -->
      <van-popup v-model="naturePickerShow" position="bottom">
        <van-picker
          title="工作性质"
          show-toolbar
          :default-index="natureIndex"
          :columns="naturePick"
          @cancel="natureCancel"
          @confirm="natureConfirm"
        />
      </van-popup>
      <!-- 性别弹窗 -->
      <van-popup v-model="sexPickerShow" position="bottom">
        <van-picker
          title="性别"
          show-toolbar
          :default-index="sexIndex"
          :columns="sexPick"
          @cancel="sexCancel"
          @confirm="sexConfirm"
        />
      </van-popup>
    </div>
  </router-layout>
</template>

<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      imgMaxSize: 5 * 1024 * 1024, // 最大上传图片大小5M
      type: "", //类别
      type_id: "",
      title: "",
      area: "",
      area_id: "",
      areaIndex: "",
      areaPickerShow: false,
      areaPick: [{ id: "0", text: "不限" }],
      time: "",
      time_id: "",
      timeIndex: "",
      timePickerShow: false,
      timePick: [
        { id: "0", text: "长期有效" },
        { id: "1", text: "一周" },
        { id: "2", text: "一个月" },
        { id: "3", text: "两个月" },
        { id: "4", text: "一年" }
      ],
      sex: "",
      sex_id: "",
      sexIndex: "",
      sexPickerShow: false,
      sexPick: [
        { id: "1", text: "男" },
        { id: "2", text: "女" }
      ],
      wage: "", //工资
      wage_id: "",
      wageIndex: "",
      wagePickerShow: false,
      wagePick: [
        { id: "0", text: "面议" },
        { id: "1", text: "1000以下" },
        { id: "2", text: "1000~2000" },
        { id: "3", text: "2000~6000" },
        { id: "4", text: "6000~8000" },
        { id: "5", text: "8000~12000" },
        { id: "6", text: "12000~30000" },
        { id: "7", text: "30000以上" }
      ],
      job: "", //职位
      school: "", //学历
      school_id: "",
      schoolIndex: "",
      schoolPickerShow: false,
      schoolPick: [
        { id: "0", text: "不限" },
        { id: "1", text: "初中及以下" },
        { id: "2", text: "高中/中专/技校" },
        { id: "3", text: "大专" },
        { id: "4", text: "本科" },
        { id: "5", text: "硕士" },
        { id: "6", text: "博士及以上" }
      ],
      year: "", //工作年限
      year_id: "",
      yearIndex: "",
      yearPickerShow: false,
      yearPick: [
        { id: "0", text: "不限" },
        { id: "1", text: "1-2年" },
        { id: "2", text: "3-5年" },
        { id: "3", text: "6-7年" },
        { id: "4", text: "8-10年" },
        { id: "5", text: "10年以上" }
      ],
      detail: "", //工作描述
      company: "", //公司名称
      nature: "", //公司性质
      nature_id: "",
      natureIndex: "",
      naturePickerShow: false,
      naturePick: [
        { id: "1", text: "私营" },
        { id: "2", text: "国有" },
        { id: "3", text: "股份制" },
        { id: "4", text: "外商独资办事处" },
        { id: "5", text: "中外合资/合作" },
        { id: "6", text: "上市公司" },
        { id: "7", text: "事业单位" },
        { id: "8", text: "政府机关" }
      ],
      name: "",
      phone: "",
      welfareSelect: [],
      welfare: [
        //公司福利
        { txt: "五险一金", value: "insurance", checked: false },
        { txt: "包住", value: "live", checked: false },
        { txt: "包吃", value: "eat", checked: false },
        { txt: "年底双薪", value: "double", checked: false },
        { txt: "周末双休", value: "weekend", checked: false },
        { txt: "交通补助", value: "traffic", checked: false },
        { txt: "加班补助", value: "overtime", checked: false },
        { txt: "餐补", value: "meal", checked: false },
        { txt: "话补", value: "tel", checked: false },
        { txt: "房补", value: "house", checked: false }
      ],
      photo: [],
      photoUrl: [],
      insurance: false,
      live: false,
      eat: false,
      double: false,
      weekend: false,
      traffic: false,
      overtime: false,
      meal: false,
      tel: false,
      house: false
    };
  },
  created() {
    this.getRouteQuery();
    this.getArea();
  },
  methods: {
    getRouteQuery() {
      this.type = this.$route.query.jobType;
      this.type_id = this.$route.query.id;
    },
    selectWelfare(item) {
      // 公司福利
      item.checked = !item.checked;
    },
    onOversize() {
      this.$toast("图片大小不能超过5M");
    },
    uploadReady(file) {
      let formData = new FormData();
      formData.append("img", file.file);
      this.$request({
        method: "post",
        url: "/cms/upload/",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {
        console.log(res);
        if (res.data) {
          this.photoUrl.push(res.data);
        }
      });
    },
    deleteImg(val, index) {
      //图片删除回调
      let deleIndex = index.index;
      let deleImg = this.photoUrl[deleIndex]; //删除的服务器上对应图片
      this.photoUrl.splice(deleIndex, 1);
      this.$request({
        method: "post",
        url: "/cms/deleteImg",
        data: {
          img: deleImg
        }
      }).then(res => {
        console.log(res);
      });
    },
    typePicker() {
      this.typePickerShow = true;
    },
    typeCancel() {
      this.typePickerShow = false;
    },
    typeConfirm(val, index) {
      this.typePickerShow = false;
      this.type = val;
      this.typeIndex = String(index);
    },
    areaPicker() {
      this.areaPickerShow = true;
    },
    areaCancel() {
      this.areaPickerShow = false;
    },
    areaConfirm(item, index) {
      this.areaPickerShow = false;
      this.areaIndex = String(index);
      this.area = item.text;
      this.area_id = item.id;
    },
    timePicker() {
      this.timePickerShow = true;
    },
    timeCancel() {
      this.timePickerShow = false;
    },
    timeConfirm(item, index) {
      this.timePickerShow = false;
      this.timeIndex = String(index);
      this.time = item.text;
      this.time_id = item.id;
    },
    wagePicker() {
      this.wagePickerShow = true;
    },
    wageCancel() {
      this.wagePickerShow = false;
    },
    wageConfirm(item, index) {
      this.wagePickerShow = false;
      this.wageIndex = String(index);
      this.wage = item.text;
      this.wage_id = item.id;
    },
    schoolPicker() {
      this.schoolPickerShow = true;
    },
    schoolCancel() {
      this.schoolPickerShow = false;
    },
    schoolConfirm(item, index) {
      this.schoolPickerShow = false;
      this.schoolIndex = String(index);
      this.school = item.text;
      this.school_id = item.id;
    },
    yearPicker() {
      this.yearPickerShow = true;
    },
    yearCancel() {
      this.yearPickerShow = false;
    },
    yearConfirm(item, index) {
      this.yearPickerShow = false;
      this.yearIndex = String(index);
      this.year = item.text;
      this.year_id = item.id;
    },
    naturePicker() {
      this.naturePickerShow = true;
    },
    natureCancel() {
      this.naturePickerShow = false;
    },
    natureConfirm(item, index) {
      this.naturePickerShow = false;
      this.natureIndex = String(index);
      this.nature = item.text;
      this.nature_id = item.id;
    },
    sexPicker() {
      this.sexPickerShow = true;
    },
    sexCancel() {
      this.sexPickerShow = false;
    },
    sexConfirm(item, index) {
      this.sexPickerShow = false;
      this.sexIndex = String(index);
      this.sex = item.text;
      this.sex_id = item.id;
    },
    getArea() {
      this.$request({
        method: "get",
        url: "/cms/area/"
      }).then(res => {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let objdata = `{
                "id": "${item.id}",
                "text": "${item.title}"
               }`;
            this.areaPick.push(JSON.parse(objdata));
          });
        }
      });
    },
    subTxtData() {
      let imgs = this.photoUrl.join(",");
      this.$request({
        method: "post",
        url: "/cms/recruit/",
        data: {
          tid: this.type_id,
          title: this.title,
          area_id: this.area_id,
          valid_time: this.time_id,
          sex: this.sex_id,
          money: this.wage_id,
          position: this.job,
          education: this.school_id,
          work_years: this.year_id,
          description: this.detail,
          company: this.company,
          company_type: this.nature_id,
          name: this.name,
          phone: this.phone, //
          imgs: imgs,
          insurance: this.insurance,
          live: this.live,
          eat: this.eat,
          double: this.double,
          weekend: this.weekend,
          traffic: this.traffic,
          overtime: this.overtime,
          meal: this.meal,
          tel: this.tel,
          house: this.house
        }
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$toast.clear();
          this.$toast.success({
            message: "发布成功,请等待审核",
            forbidClick: true,
            duration: 500
          });
          setTimeout(() => {
            this.$router.replace({
              path:"/RecruitmentList",
              query:{
                infoType: '2'
              }
            });
          }, 700);
        }
      });
    },
    welfareCheck(type) {
      // 公司福利
      let _this = this;
      let welfareSelect = this.welfareSelect;
      if (welfareSelect.includes(type)) {
        _this[type] = true;
      } else {
        _this[type] = false;
      }
    },
    submit() {
      this.welfare.forEach(item => {
        this.welfareCheck(item.value);
      });
      let reg = /^[1][3,4,5,7,8][0-9]{9}$/; //手机号正则验证
      let title = this.title;
      let area_id = this.area_id;
      let valid_time = this.time_id;
      let sex_id = this.sex_id;
      let wage_id = this.wage_id;
      let job = this.job;
      let school_id = this.school_id;
      let year_id = this.year_id;
      let company = this.company;
      let company_type = this.nature_id;
      let name = this.name;
      let phone = this.phone;
      if (!title) {
        this.$toast({
          message: "请输入标题",
          forbidClick: true
        });
      } else if (!area_id) {
        this.$toast({
          message: "请选择区域",
          forbidClick: true
        });
      } else if (!valid_time) {
        this.$toast({
          message: "请选择有效期",
          forbidClick: true
        });
      } else if (!sex_id) {
        this.$toast({
          message: "请选择性别",
          forbidClick: true
        });
      } else if (!wage_id) {
        this.$toast({
          message: "请选择月薪",
          forbidClick: true
        });
      } else if (!job) {
        this.$toast({
          message: "请输入职位",
          forbidClick: true
        });
      } else if (!school_id) {
        this.$toast({
          message: "请选择学历",
          forbidClick: true
        });
      } else if (!year_id) {
        this.$toast({
          message: "请选择工作年限",
          forbidClick: true
        });
      } else if (!company) {
        this.$toast({
          message: "请填写公司名称",
          forbidClick: true
        });
      } else if (!company_type) {
        this.$toast({
          message: "请选择公司性质",
          forbidClick: true
        });
      } else if (!name) {
        this.$toast({
          message: "请填写联系人",
          forbidClick: true
        });
      } else if (!phone) {
        this.$toast({
          message: "请填写手机号",
          forbidClick: true
        });
      } else if (!reg.test(phone)) {
        this.$toast({
          message: "请填写正确的手机号",
          forbidClick: true
        });
      } else {
        this.$toast.loading({
          loadingType: "spinner",
          forbidClick: true,
          duration: 0
        });
        this.subTxtData();
      }
      // submit end
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.submit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  text-align: center;
  background: #567cf3;
  color: #fff;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.4rem;
}
.form {
  padding-bottom: 1.5rem;
  .line {
    background: #f5f5f5;
    height: 0.2rem;
  }
  .form_t {
    background: #f5f5f5;
    color: #9e9e9e;
    font-size: 0.32rem;
    padding: 0.2rem 0.3rem;
    letter-spacing: 0.03rem;
  }
  .input {
    border-bottom: 1px solid #ebedf0;
    .input_t {
      color: #646566;
      padding: 0.26667rem 0.42667rem 0.05rem 0.42667rem;
      font-size: 0.37333rem;
    }
    .checkBox {
      display: flex;
      justify-content: flex-start;
      padding: 0.26667rem 0.42667rem 0.3rem 0.42667rem;
      .checkItem {
        background: #f6f6f6;
        color: #999;
        text-align: center;
        height: 0.9rem;
        line-height: 0.9rem;
        padding: 0 0.25rem;
        border-radius: 0.85rem;
        margin-top: 0.15rem;
        margin-bottom: 0.15rem;
        margin-right: 0.2rem;
        .van-checkbox {
          height: 100%;
          width: 100%;
        }
      }
      .checkItem.active {
        background: #567cf3;
      }
    }
    .photo {
      padding: 0.26667rem 0.42667rem 0.05rem 0.42667rem;
    }
  }
  .input.noborder {
    border-bottom: none;
  }
}
</style>